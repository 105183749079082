<template>
  <div class="prestation">
    <div class="container is-fluid">
      <page-header
        :entity="$EntitiesName.Prestation"
        :edit="edit"
        :save="save"
        :cancel="cancel"
        :remove="remove"
      >
        <h1 class="title" data-test="sel-role-header">
          <breadcrumb location="/prestations" text="Prestation"></breadcrumb>
          {{ prestation.eotp }}
        </h1>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <span>Informations</span>
        </div>
        <div class="panel-block is-block no-border">
          <div class="columns">
            <div class="column is-half" v-if="prestation">
              <text-field
                label="Nom"
                v-model="prestation.name"
                required
              ></text-field>
              <many2one-field
                label="Catégorie"
                v-model="category"
                :fetch="$Api.Category.fetchParentCategories"
                reference="name"
                :entity="$EntitiesName.Category"
                :columns="{name: 'Nom'}"
                :hasFilter="true"
                :inline="true"
                :edit="editMode"
                readonly
                required
              ></many2one-field>
              <many2one-field
                label="Sous catégorie"
                v-model="subCategory"
                :fetch="(p) => $Api.Category.fetchSubCategoriesForParent(p, category.id)"
                reference="name"
                :columns="{'parent.name': 'Parent', name: 'Nom'}"
                :inline="true"
                :hasFilter="true"
                :edit="editMode"
                required
                :readonly="!category.id"
                name="subcategory2one"
              >
                <span slot="helper" v-if="!category.id">Nécessite une catégorie</span>
              </many2one-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "prestation",
  props: ["id"],
  computed: {
    ...mapGetters({
      editMode: "App/isEdition",
    }),
    category: {
      get() {
        if (!this.prestation.category) return {};
        return this.prestation.category.parent ? this.prestation.category.parent : this.prestation.category;
      },
      set(val) {
        if (this.prestation) { this.$set(this.prestation, 'category', val); }
      },
    },
    subCategory: {
      get() {
        if (!this.prestation.category) {
          return {};
        }
        return this.prestation.category.parent ? this.prestation.category : null;
      },
      set(val) {
        if (this.prestation.category) {
          this.prestation.category = val;
          this.prestation.category.parent = this.category;
        }
      },
    },
  },
  data() {
    return {
      prestation: {},
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get(`/prestations/${this.id}`).then((r) => {
        this.prestation = r.data;
        this.$store.dispatch("App/setEditing", false);
      });
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios
            .delete(`/prestations/${this.id}`)
            .then((res) => {
              if (res.data.success) {
                this.$router.replace("/prestations");
              }
            }),
      );
    },
    save() {
      return axios
        .put(`/prestations/${this.id}`, this.prestation)
        .then((response) => {
          if (response.data.success) {
            this.fetchData();
          }
        });
    },
    edit() {
      this.$store.dispatch("App/setEditing", true);
    },
    cancel() {
      this.fetchData();
    },
  },
};
</script>

<style lang='scss' scoped>

</style>